
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import {
		PIXCAP_DISCORD_LINK,
		PIXCAP_INSTAGRAM_LINK,
		PIXCAP_LINKEDIN_LINK,
		PIXCAP_TIKTOK_LINK,
		PIXCAP_TWITTER_LINK,
		PIXCAP_YOUTUBE_LINK,
	} from '@pixcap/ui-core/constants/app.constants';
	import FilterInput from '@/components/pixcap-library/FilterInput.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';
	import IconLTwitch from '@pixcap/ui-library/components/Icons/IconLTwitch.vue';
	import IconLInstagram from '@pixcap/ui-library/components/Icons/IconLInstagram.vue';
	import IconLFacebook from '@pixcap/ui-library/components/Icons/IconLFacebook.vue';
	import IconLLinkedIn from '@pixcap/ui-library/components/Icons/IconLLinkedIn.vue';
	import IconLTikTok from '@pixcap/ui-library/components/Icons/IconLTikTok.vue';
	import IconLTwitter from '@pixcap/ui-library/components/Icons/IconLTwitter.vue';
	import IconLYoutube from '@pixcap/ui-library/components/Icons/IconLYoutube.vue';
	import IconLDiscord from '@pixcap/ui-library/components/Icons/IconLDiscord.vue';
	import { actionsWrapper as LibraryActions, mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import {
		IAssetsLibraryState,
		LIBRARY_TAB,
		NAMESPACE as LIBRARY_NAMESPACE,
		SEARCH_ITEM_TYPE,
		SEARCH_ROUTES,
	} from '@/models/store/pixcapassets.interface';
	import { actionsWrapper as BlogActions } from '@/store/blog/wrapper';
	import { actionsWrapper as OrganismActions } from '@/store/organism/wrapper';
	import { mapState } from 'vuex';
	import { DEFAULT_APP_LOCALE } from '@/constants/app.constants';

	@Component({
		name: 'ExploreFooter',
		components: {
			IconLDiscord,
			IconLYoutube,
			IconLTwitter,
			IconLTikTok,
			IconLLinkedIn,
			IconLFacebook,
			IconLInstagram,
			IconLTwitch,
			PixcapLogo,
			BodyText,
			FilterInput,
			HeadingText,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				librarySearch: (state: IAssetsLibraryState) => state.librarySearch,
				activeExplorerTab: (state: IAssetsLibraryState) => state.activeExplorerTab,
				activeSearchItemType: (state: IAssetsLibraryState) => state.activeSearchItemType,
			}),
		},
	})
	export default class ExploreFooter extends Vue {
		librarySearch: IAssetsLibraryState['librarySearch'];
		activeExplorerTab: IAssetsLibraryState['activeExplorerTab'];
		activeSearchItemType: IAssetsLibraryState['activeSearchItemType'];

		$refs: {
			filterHighlight: HTMLInputElement;
		};

		PIXCAP_INSTAGRAM_LINK = PIXCAP_INSTAGRAM_LINK;
		PIXCAP_TWITTER_LINK = PIXCAP_TWITTER_LINK;
		PIXCAP_YOUTUBE_LINK = PIXCAP_YOUTUBE_LINK;
		PIXCAP_DISCORD_LINK = PIXCAP_DISCORD_LINK;
		PIXCAP_TIKTOK_LINK = PIXCAP_TIKTOK_LINK;
		PIXCAP_LINKEDIN_LINK = PIXCAP_LINKEDIN_LINK;

		selectedFilter: SEARCH_ITEM_TYPE | LIBRARY_TAB = this.listSortByItemType[0].value;
		isFetchingSeo = false;
		SEARCH_ITEM_TYPE = SEARCH_ITEM_TYPE;
		SEARCH_ROUTES = SEARCH_ROUTES;
		LIBRARY_TAB = LIBRARY_TAB;
		seoContent = [];
		blogs = [];

		@Watch('librarySearch')
		handleSearchChange() {
			this.fetchSeoSection();
		}

		@Watch('activeExplorerTab')
		handleChangeFilterTab() {
			this.fetchSeoSection();
			this.selectedFilter = this.activeExplorerTab as LIBRARY_TAB;
		}

		@Watch('activeSearchItemType')
		handleChangeFilterTabOnSearchPage() {
			this.fetchSeoSection();
			this.selectedFilter = this.activeSearchItemType as SEARCH_ITEM_TYPE;
		}

		@Watch('selectedFilter')
		onSelectedFilterChange() {
			this.fetchSeoSection();
			switch (this.selectedFilter) {
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					this.$refs.filterHighlight.style.left = 'calc(100%/3)';
					break;
				case SEARCH_ITEM_TYPE.MOCKUPS:
				case LIBRARY_TAB.MOCKUPS:
					this.$refs.filterHighlight.style.left = 'calc(100% - calc(100%/3) - 4px)';
					break;
				case LIBRARY_TAB.THREE_D_PACKS:
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					this.$refs.filterHighlight.style.left = '4px';
					break;
			}
		}

		get routerLinkTo() {
			switch (this.selectedFilter) {
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					return SEARCH_ROUTES.PACK_CHARACTERS;

				case SEARCH_ITEM_TYPE.MOCKUPS:
				case LIBRARY_TAB.MOCKUPS:
					return SEARCH_ROUTES.PACK_MOCKUPS;

				case LIBRARY_TAB.THREE_D_PACKS:
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					return SEARCH_ROUTES.PACK_ICONS;
			}
		}

		get title() {
			if (this.librarySearch) return this.$t('footer.searched_title');
			return this.$t('footer.title');
		}

		get shouldShowSeoContent() {
			return this.$route.name !== 'PricingPage';
		}

		get listSortByItemType() {
			return [
				{
					label: this.$t('common_elements.icons'),
					value: LIBRARY_TAB.THREE_D_PACKS,
				},
				{
					label: this.$t('common_elements.characters'),
					value: LIBRARY_TAB.THREE_D_CHARACTERS,
				},
				{
					label: this.$t('common_elements.mockups'),
					value: LIBRARY_TAB.MOCKUPS,
				},
			];
		}

		get footerContent() {
			return [
				{
					title: this.$t('footer.footer_section.explore.title'),
					items: [
						{
							label: this.$t('common_elements.icons'),
							to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_PACKS } },
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.THREE_D_PACKS);
							},
						},
						{
							label: this.$t('common_elements.characters'),
							to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_CHARACTERS } },
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.THREE_D_CHARACTERS);
							},
						},
						{
							label: this.$t('common_elements.mockups'),
							to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.MOCKUPS } },
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.MOCKUPS);
							},
						},
					],
				},
				{
					title: this.$t('footer.footer_section.features.title'),
					items: [
						{
							label: this.$t('footer.footer_section.features.animated_mockup'),
							href: `${process.env.ROOT_URL}/animated-mockups-marketing`,
						},
						{
							label: this.$t('footer.footer_section.features.ai_style_transfer'),
							href: `${process.env.ROOT_URL}/ai`,
						},
						{
							label: this.$t('footer.footer_section.features.character_library'),
							href: `${process.env.ROOT_URL}/character-library`,
						},
						{
							label: this.$t('footer.footer_section.features.material_library'),
							href: `${process.env.ROOT_URL}/material-library`,
						},
					],
				},
				{
					title: this.$t('footer.footer_section.company.title'),
					items: [
						{
							label: this.$t('footer.footer_section.company.pricing'),
							to: { name: 'PricingPage' },
							handler: () => {
								this.$router.push({ name: 'PricingPage' });
							},
						},
						{
							label: this.$t('footer.footer_section.company.enterprise'),
							href: `${process.env.ROOT_URL}/enterprise`,
						},
						{
							label: this.$t('footer.footer_section.company.privacy_policy'),
							href: `https://pixcap.gitbook.io/pixcap-docs/legal/privacy-policy`,
						},
						{
							label: this.$t('footer.footer_section.company.terms_of_service'),
							href: `https://pixcap.gitbook.io/pixcap-docs/legal/terms`,
						},
						{
							label: this.$t('footer.footer_section.company.contact_us'),
							to: { name: 'ContactUsPage' },
							handler: () => {
								this.$router.push({ name: 'ContactUsPage' });
							},
						},
					],
				},
				{
					title: this.$t('footer.footer_section.resources.title'),
					items: [
						{
							label: this.$t('footer.footer_section.resources.glossary'),
							href: `${process.env.ROOT_URL}/glossary`,
						},
						{
							label: this.$t('footer.footer_section.resources.contributor'),
							href: `${process.env.ROOT_URL}/creators`,
						},
					],
				},
				{
					title: this.$t('footer.footer_section.tools.title'),
					items: [
						{
							label: this.$t('footer.footer_section.tools.ai_3d_model'),
							href: `${process.env.ROOT_URL}/tools/ai-3d-model-generator`,
						},
						{
							label: this.$t('footer.footer_section.tools.ai_character'),
							href: `${process.env.ROOT_URL}/tools/ai-character-generator`,
						},
						{
							label: this.$t('footer.footer_section.tools.ai_girl'),
							href: `${process.env.ROOT_URL}/tools/ai-girl-generator`,
						},
						{
							label: this.$t('footer.footer_section.tools.ai_background'),
							href: `${process.env.ROOT_URL}/tools/ai-background-generator`,
						},
					],
				},
				{
					title: this.$t('footer.footer_section.create.title'),
					items: [
						{
							label: this.$t('footer.footer_section.create.mug_design'),
							href: `${process.env.ROOT_URL}/create/mug-templates`,
						},
						{
							label: this.$t('footer.footer_section.create.sticker_design'),
							href: `${process.env.ROOT_URL}/create/sticker-templates`,
						},
						{
							label: this.$t('footer.footer_section.create.friendsgiving_invitation'),
							href: `${process.env.ROOT_URL}/create/friendsgiving-invitation`,
						},
						{
							label: this.$t('footer.footer_section.create.poster_mockup'),
							href: `${process.env.ROOT_URL}/create/poster-mockups`,
						},
						{
							label: this.$t('footer.footer_section.create.iphone_mockup'),
							href: `${process.env.ROOT_URL}/create/iphone-mockups`,
						},
					],
				},
				{
					title: this.$t('footer.footer_section.blogs.title'),
					items: [
						...this.latestArticles,
						{
							label: this.$t('footer.footer_section.blogs.all_blogs'),
							to: {
								name: 'Blog',
								params: {
									locale: this.$i18n.locale !== DEFAULT_APP_LOCALE ? this.$i18n.locale : null,
									byPassPixcapRouter: 'true',
								},
							},
							handler: () => {
								const url = this.$router.resolve({
									name: 'Blog',
									params: {
										locale: this.$i18n.locale !== DEFAULT_APP_LOCALE ? this.$i18n.locale : null,
										byPassPixcapRouter: 'true',
									},
								}).href;
								window.open(url, '_blank');
							},
						},
					],
				},
			];
		}

		get latestArticles() {
			if (!this.blogs?.length) return [];

			return this.blogs?.map((article) => {
				return {
					label: article.title,
					to: {
						name: 'Article',
						params: {
							articleSlug: article.slug,
							locale: this.$i18n.locale !== DEFAULT_APP_LOCALE ? this.$i18n.locale : null,
							byPassPixcapRouter: 'true',
						},
					},
					handler: () => {
						const url = this.$router.resolve({
							name: 'Article',
							params: {
								articleSlug: article.slug,
								locale: this.$i18n.locale !== DEFAULT_APP_LOCALE ? this.$i18n.locale : null,
								byPassPixcapRouter: 'true',
							},
						}).href;
						window.open(url, '_blank');
					},
				};
			});
		}

		navigate(link) {
			window.location.href = link;
		}

		navigationItemHandler(key) {
			LibraryMutations.setExplorerTab(this.$store, key);
			if (key === LIBRARY_TAB.THREE_D_CHARACTERS) {
				LibraryMutations.updateShowOnlyAnimatedFiles(this.$store, null);
			}
			if (this.$route.name === 'LibraryPage') {
				const libraryRoute = this.$router.resolve(key);
				window.history.replaceState({}, '', libraryRoute.href);
			} else {
				this.$router.push({ name: 'LibraryPage', params: { tab: key } });
			}
		}

		handleClickSeoItem(tag) {
			let type;
			switch (this.selectedFilter) {
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					type = SEARCH_ROUTES.PACK_CHARACTERS;
					break;
				case SEARCH_ITEM_TYPE.MOCKUPS:
				case LIBRARY_TAB.MOCKUPS:
					type = SEARCH_ROUTES.PACK_MOCKUPS;
					break;
				case LIBRARY_TAB.THREE_D_PACKS:
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					type = SEARCH_ROUTES.PACK_ICONS;
					break;
			}
			this.$router.push({
				name: 'LibraryExploreSearchList',
				params: { tab: type, searchQuery: tag },
			});
		}

		async fetchSeoSection() {
			let type;
			switch (this.selectedFilter) {
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					type = 'characters';
					break;
				case SEARCH_ITEM_TYPE.MOCKUPS:
				case LIBRARY_TAB.MOCKUPS:
					type = 'mockups';
					break;
				case LIBRARY_TAB.THREE_D_PACKS:
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					type = 'icons';
					break;
			}
			this.isFetchingSeo = true;
			if (this.librarySearch) {
				this.seoContent = await LibraryActions.fetchRelatedKeywords(this.$store, { keyword: this.librarySearch, type });
			} else this.seoContent = await OrganismActions.getFooterSeo(this.$store, { type });
			this.isFetchingSeo = false;
		}

		async fetch() {
			const [articles, _] = await BlogActions.getArticles(this.$store, { pageSize: 3, overrideBlogLocale: this.$i18n.locale });
			this.blogs = articles;
			await this.fetchSeoSection();
		}
	}
